import React, { useEffect, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import dayjs from 'dayjs';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select } from '@components/MUI/Select';
import { Button } from '@components/MUI/Button';
import styles from './DelegationForm.module.scss';
import {
  getManagerCustomerProjects,
  selectProjectOptions,
  useDetailProjectQuery,
} from '@store/project/project.slice';

import {
  loadOrganizations,
  selectOrganizationOptions,
  loadClouds,
  selectCloudOptions,
  loadBillingAccounts,
  selectBillingAccountOptions,
  loadInfrastructures,
  selectInfrastructureOptions,
  loadCloudDelegations,
  selectCloudDelegationOptions,
  selectCloudDelegations,
} from '@store/cloud/cloud.slice';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';
import { FormField } from '@components/Form/FormField';
import { useParams } from 'react-router-dom';
import { Autocomplete, AutocompleteOption } from '@components/MUI/Autocomplete';
import { useGetBudgetLimitActionWithParent } from './delegationHooks/useGetBudgetLimitActionWithParent';
import { useSetFieldWithParent } from './delegationHooks/useSetFieldWithParent';
import { CloudDelegation } from '@store/cloud/contracts';

export type DelegationFormProps = {
  value: DelegationFormValue;
  submit: (
    value: DelegationFormValue,
    form: FormikBag<DelegationFormProps, DelegationFormValue>,
  ) => void;
};

export type DelegationFormValue = {
  name: string;
  parentId: string;
  organizationId: string;
  organizationName: string;
  cloudId: string;
  cloudName: string;
  billingAccountId: string;
  billingAccountName: string;
  projectId: string;
  userEmail: AutocompleteOption;
  isEnabledCreateInfrastructure: boolean;
  infrastructureId: string;
  allocateBudget: boolean;
  currencyId: string;
  budget: number;
  budgetLimitActionId: string;
  resetBudgetTypeId: string;
  budgetEndDate: dayjs.Dayjs;
  parent?: CloudDelegation;
};

const DelegationFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  organizationId: Yup.string().required('Поле обязательно для заполнения'),
  cloudId: Yup.string().required('Поле обязательно для заполнения'),
  billingAccountId: Yup.string().required('Поле обязательно для заполнения'),
  userEmail: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).nullable(),
  isAllocateBudget: Yup.boolean(),
  budget: Yup.number()
    .when('isAllocateBudget', {
      is: true,
      then: Yup.number().min(1, 'Бюджет должен быть положительным'),
    })
    .test('lessThanParentBudget', 'Бюджет не должен превышать бюджет основания', function (value) {
      const schema = this.parent;
      if (schema.parent.budget && value) return value <= schema.parent.budget;
      return true;
    }),
  budgetEndDate: Yup.date()
    .min(dayjs().add(-1, 'day'), `Выберите дату от ${dayjs().add(1, 'day').format('DD.MM.YYYY')}`)
    .nullable()
    .test(
      'lessThanParentBudgetEndDate',
      'Дата окончания бюджета не должна превышать дату окончания бюджета основания',
      function (value) {
        const schema = this.parent;
        if (schema.parent.budgetEndDate && value) return value <= schema.parent.budgetEndDate;
        return true;
      },
    ),
  parentId: Yup.string(),
  projectId: Yup.string(),
  infrastructureId: Yup.string(),
  currencyId: Yup.string().when('isAllocateBudget', {
    is: true,
    then: Yup.string().required('Поле обязательно для заполнения'),
  }),
  budgetLimitActionId: Yup.string(),
  resetBudgetTypeId: Yup.string(),
  parent: Yup.object({
    name: Yup.string(),
    organizationId: Yup.string(),
    cloudId: Yup.string(),
    billingAccountId: Yup.string(),
    userEmail: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).nullable(),
    isAllocateBudget: Yup.boolean(),
    budget: Yup.number().when('isAllocateBudget', {
      is: true,
      then: Yup.number().min(1, 'Бюджет должен быть положительным'),
    }),
    budgetEndDate: Yup.date()
      .min(dayjs().add(-1, 'day'), `Выберите дату от ${dayjs().add(1, 'day').format('DD.MM.YYYY')}`)
      .nullable(),
    parentId: Yup.string(),
    projectId: Yup.string(),
    infrastructureId: Yup.string(),
    currencyId: Yup.string().when('isAllocateBudget', {
      is: true,
      then: Yup.string(),
    }),
    budgetLimitActionId: Yup.string(),
    resetBudgetTypeId: Yup.string(),
  }).nullable(),
});

const FormComponent = () => {
  const form = useFormikContext<DelegationFormValue>();

  const { delegationId } = useParams();
  const currencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption);
  const budgetLimitAction = useDictionary(
    DictionaryName.CLOUD_BUDGET_LIMIT_ACTION,
    fromDictionaryToSelectOption,
  );
  const resetBudgetTypes = [
    { value: 'NEVER', label: 'Никогда' },
    { value: 'MONTHLY', label: 'Ежемесячно' },
  ];

  const dispatch = useAppDispatch();

  const organizationOptions = useAppSelector(selectOrganizationOptions);
  const cloudOptions = useAppSelector(selectCloudOptions);
  const billingAccountOptions = useAppSelector(selectBillingAccountOptions);
  const projectOptions = useAppSelector(selectProjectOptions);
  const infrastructureOptions = useAppSelector(selectInfrastructureOptions);

  const cloudDelegationOptions = useAppSelector(selectCloudDelegationOptions);
  const cloudDelegations = useAppSelector(selectCloudDelegations);
  const { data: projectDetail } = useDetailProjectQuery(form.values.projectId, {
    skip: !form.values.projectId,
  });

  form.validationSchema = DelegationFormSchema;

  useEffect(() => {
    dispatch(loadCloudDelegations({ limit: 50 }));
    dispatch(
      loadOrganizations({ delegationId: form.values.parentId == null ? '' : form.values.parentId }),
    );
    dispatch(
      loadBillingAccounts({
        delegationId: form.values.parentId == null ? '' : form.values.parentId,
      }),
    );
    dispatch(loadInfrastructures({}));
    dispatch(getManagerCustomerProjects({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      loadOrganizations({ delegationId: form.values.parentId == null ? '' : form.values.parentId }),
    );
    dispatch(
      loadBillingAccounts({
        delegationId: form.values.parentId == null ? '' : form.values.parentId,
      }),
    );
    form.setFieldValue('organizationId', null);
    form.setFieldValue('cloudId', null);
    form.setFieldValue('billingAccountId', null);
  }, [form.values.parentId]);

  useEffect(() => {
    dispatch(
      loadClouds({
        delegationId: form.values.parentId == null ? '' : form.values.parentId,
        parentId: form.values.organizationId,
      }),
    );
    let organizationName = '';
    if (form.values.organizationId) {
      let selected = organizationOptions.find((item) => item.value === form.values.organizationId);
      if (selected !== undefined) organizationName = selected.label;
    }
    form.setFieldValue('organizationName', organizationName);
  }, [form.values.organizationId]);

  useEffect(() => {
    let cloudName = '';
    if (form.values.cloudId) {
      let selected = cloudOptions.find((item) => item.value === form.values.cloudId);
      if (selected !== undefined) cloudName = selected.label;
    }
    form.setFieldValue('cloudName', cloudName);
  }, [form.values.cloudId]);

  useEffect(() => {
    let billingAccountName = '';
    if (form.values.billingAccountId) {
      let selected = cloudOptions.find((item) => item.value === form.values.billingAccountId);
      if (selected !== undefined) billingAccountName = selected.label;
    }
    form.setFieldValue('billingAccountName', billingAccountName);
  }, [form.values.billingAccountId]);

  /*
  const onChangeProject = (event: SelectChangeEvent) => {
    //dispatch(loadClouds(event.target.value));
    form.setFieldValue('projectId', event.target.value);
  };
*/

  const cloudDelegation = useMemo(() => {
    const parentId = form.values.parentId;
    return cloudDelegations?.find((delegation) => delegation.id === parentId);
  }, [form.values.parentId]);

  useSetFieldWithParent(form, cloudDelegation);

  useEffect(() => {
    if (cloudDelegation) {
      form.setFieldValue('parent', cloudDelegation);
    }
  }, [cloudDelegation]);

  const conditionalOrganizationOptions = useMemo(() => {
    const allOrganizationOptions = organizationOptions;
    cloudDelegations.forEach((delegation) => {
      allOrganizationOptions.push({
        label: delegation.organization?.resourceName || '',
        value: delegation.organization?.id || '',
      });
    });

    if (form.values.parentId !== '') return allOrganizationOptions;
    return organizationOptions;
  }, [form.values.parentId]);

  const conditionalCloudOptions = useMemo(() => {
    const allCloudOptions = cloudOptions;
    cloudDelegations.forEach((delegation) => {
      allCloudOptions.push({
        label: delegation.cloud?.resourceName || '',
        value: delegation.cloud?.id || '',
      });
    });

    if (form.values.parentId) return allCloudOptions;
    return cloudOptions;
  }, [form.values.parentId]);

  const conditionalBillingAccountOptions = useMemo(() => {
    const allBillingAccountsOptions = billingAccountOptions;
    cloudDelegations.forEach((delegation) => {
      allBillingAccountsOptions.push({
        value: delegation.billingAccount?.resourceId || '',
        label: delegation.billingAccount?.resourceName || '',
      });
    });

    if (form.values.parentId) return allBillingAccountsOptions;
    return billingAccountOptions;
  }, [form.values.parentId]);

  const conditionalProjectsOptions = useMemo(() => {
    const allProjects = projectOptions;
    cloudDelegations.forEach((delegation) => {
      allProjects.push({
        value: delegation.project?.id || '',
        label: delegation.project?.name || '',
      });
    });

    if (form.values.parentId) return allProjects;
    return projectOptions;
  }, [form.values.parentId]);

  const conditionalInfrastructureOptions = useMemo(() => {
    const allInfrastructures = infrastructureOptions;
    cloudDelegations.forEach((delegation) => {
      allInfrastructures.push({
        value: delegation.infrastructure?.id || '',
        label: delegation.infrastructure?.name || '',
      });
    });

    if (form.values.parentId) return allInfrastructures;
    return infrastructureOptions;
  }, [form.values.parentId]);

  const resetBudgetTypesWithParent = useMemo(() => {
    if (cloudDelegation) {
      if (cloudDelegation.resetBudgetTypeId === 'NEVER') {
        return [{ value: 'NEVER', label: 'Никогда' }];
      }
      if (cloudDelegation.resetBudgetTypeId === 'MONTHLY') {
        return [
          { value: 'NEVER', label: 'Никогда' },
          { value: 'MONTHLY', label: 'Ежемесячно' },
        ];
      }
    }
    return [
      { value: 'NEVER', label: 'Никогда' },
      { value: 'MONTHLY', label: 'Ежемесячно' },
    ];
  }, [cloudDelegation]);

  const optionalBudgetLimitAction = useGetBudgetLimitActionWithParent(
    budgetLimitAction,
    form,
    cloudDelegation,
  );

  const usersOptions = useMemo(() => {
    if (projectDetail) {
      const usersOptions: AutocompleteOption[] =
        projectDetail?.teamPositions
          ?.filter((position) => position.email)
          ?.map((position) => ({
            label: position?.email,
            value: position?.email,
          })) || [];
      return usersOptions;
    }
    return [];
  }, [projectDetail]);

  const disableCreateInfrastructure = useCallback(async () => {
    form.setFieldValue('isEnabledCreateInfrastructure', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffEnabledCreateInfrastructure = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить возможность создавать инфраструктуру?"
          btnProp="Отключить"
          onSubmit={disableCreateInfrastructure}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleEnabledCreateInfrastructure = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffEnabledCreateInfrastructure();
    } else form.setFieldValue('isEnabledCreateInfrastructure', event.target.checked);
  };
  /*
  const onChangeInfrastructure = (event: SelectChangeEvent) => {
    //dispatch(loadClouds(event.target.value));
    form.setFieldValue('InfrastuctureId', event.target.value);
  };
*/
  const disableAllocateBudget = useCallback(async () => {
    form.setFieldValue('isAllocateBudget', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffAllocateBudget = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить бюджет?"
          btnProp="Отключить"
          onSubmit={disableAllocateBudget}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleAllocateBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffAllocateBudget();
    } else form.setFieldValue('allocateBudget', event.target.checked);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название делегата"
          type="text"
          className={styles.form__field}
        />

        <Select
          name="parentId"
          values={cloudDelegationOptions}
          label="Основание"
          isOutlined
          className={styles.form__field}
        />

        <Select
          name="organizationId"
          values={form.values.parentId ? conditionalOrganizationOptions : organizationOptions}
          label="Организация"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />

        <Select
          name="cloudId"
          values={form.values.parentId ? conditionalCloudOptions : cloudOptions}
          label="Облако"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />
        <Select
          name="billingAccountId"
          values={form.values.parentId ? conditionalBillingAccountOptions : billingAccountOptions}
          label="Платежный аккаунт"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />
        <Select
          name="projectId"
          values={form.values.parentId ? conditionalProjectsOptions : projectOptions}
          label="Проект"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.parentId}
        />

        <Autocomplete
          name="userEmail"
          placeholder="Email пользователя"
          className={styles.form__field}
          values={usersOptions}
          isOutlined
          freeSolo={true}
        />

        <div className="d-flex gap-4 align-items-center">
          Разрешить создавать инфраструктуру{' '}
          <FormControlLabel
            control={
              <MuiSwitch
                checked={form.values.isEnabledCreateInfrastructure}
                onChange={toggleEnabledCreateInfrastructure}
                name="isEnabledCreateInfrastructure"
              />
            }
            label=""
            disabled={!cloudDelegation?.infrastructure ? true : false}
          />
        </div>
        <br />
        {form.values.isEnabledCreateInfrastructure && (
          <Select
            name="infrastructureId"
            values={form.values.parentId ? conditionalInfrastructureOptions : infrastructureOptions}
            label="Инфраструктура"
            isOutlined
            className={styles.form__field}
            disabled={!!form.values.parentId}
          />
        )}
        <div className="d-flex gap-4 align-items-center">
          Выделить бюджет
          <FormControlLabel
            control={
              <MuiSwitch
                checked={cloudDelegation?.currency ? true : form.values.allocateBudget}
                onChange={toggleAllocateBudget}
                name="allocateBudget"
              />
            }
            disabled={cloudDelegation?.currency === null ? true : false}
            label=""
          />
        </div>
        <br />
        {form.values.allocateBudget && (
          <>
            <Select
              name={'currencyId'}
              values={currencies}
              label={'Валюта'}
              isOutlined
              value={form.values.currencyId}
              className={styles.form__item}
              disabled={cloudDelegation?.currency ? true : false}
            />
            <br />
            <InputField
              name="budget"
              type="number"
              placeholder="Бюджет"
              className={styles.form__item}
            />
            <br />

            <Select
              name={'budgetLimitActionId'}
              values={optionalBudgetLimitAction}
              label={'Действие при достижении суммы'}
              isOutlined
              value={form.values.budgetLimitActionId}
              className={styles.form__item}
            />
            <br />
            <Select
              name={'resetBudgetTypeId'}
              values={cloudDelegation ? resetBudgetTypesWithParent : resetBudgetTypes}
              label={'Период сброса бюджетного правила'}
              isOutlined
              value={form.values.resetBudgetTypeId}
              className={styles.form__item}
            />
            <br />
            <FormField
              name="budgetEndDate"
              type="datetime-local"
              placeholder="Дата окончания бюджета"
              className="mb-4 input-classical"
              floating
              floatingLabelClassName="mb-3 req-label-custom"
            />
            <br />
          </>
        )}
      </div>
      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        {delegationId ? 'Сохранить' : 'Делегировать'}
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const DelegationForm = withFormik<DelegationFormProps, DelegationFormValue>({
  displayName: 'DelegationForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      parentId: props.value.parentId,
      organizationId: props.value.organizationId,
      organizationName: props.value.organizationName,
      cloudId: props.value.cloudId,
      cloudName: props.value.cloudName,
      billingAccountId: props.value.billingAccountId,
      billingAccountName: props.value.billingAccountName,
      projectId: props.value.projectId,
      userEmail: props.value.userEmail,
      isEnabledCreateInfrastructure: props.value.isEnabledCreateInfrastructure,
      infrastructureId: props.value.infrastructureId,
      allocateBudget: props.value.allocateBudget,
      currencyId: props.value.currencyId,
      budget: props.value.budget,
      budgetLimitActionId: props.value.budgetLimitActionId,
      resetBudgetTypeId: props.value.resetBudgetTypeId,
      budgetEndDate: props.value.budgetEndDate,
      parent: undefined,
    };
  },
  enableReinitialize: true,
  validationSchema: DelegationFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
