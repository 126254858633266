import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select } from '@components/MUI/Select';
import { Button } from '@components/MUI/Button';
import styles from './InfrastructureForm.module.scss';
import {
  loadOrganizations,
  selectOrganizationOptions,
  loadClouds,
  selectCloudOptions,
  loadFolders,
  selectFolderOptions,
  loadNetworks,
  selectNetworkOptions,
  loadCloudDelegations,
  selectCloudDelegationOptions,
  selectCloudDelegations,
} from '@store/cloud/cloud.slice';

export type InfrastructureFormProps = {
  value: InfrastructureFormValue;
  submit: (
    value: InfrastructureFormValue,
    form: FormikBag<InfrastructureFormProps, InfrastructureFormValue>,
  ) => void;
};

export type InfrastructureFormValue = {
  id?: string; // Идентификатор
  name: string; // Наименование
  organizationId: string;
  organizationName: string;
  cloudId: string;
  cloudName: string;
  folderId: string;
  folderName: string;
  networkId: string;
  networkName: string;
  serviceAccountId: string;
  serviceAccountName: string;
  delegationId: string; // Основание создания инфраструктуры
};

const InfrastructureFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  organizationId: Yup.string().required('Поле обязательно для заполнения'),
  cloudId: Yup.string().required('Поле обязательно для заполнения'),
  folderId: Yup.string(),
  folderName: Yup.string()
    .required('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения')
    .matches(
      /^[a-z][-a-z0-9]{1,61}[a-z0-9]$/,
      'Название каталога не соответствует требованиям, например назовите: project123 или folder2025 или list',
    ),
  networkId: Yup.string(),
  networkName: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(
      /^[a-z][-a-z0-9]{1,61}[a-z0-9]$/,
      'Название сети не соответствует требованиям, например назовите: network123 или net',
    ),
  delegationId: Yup.string(),
});

const FormComponent = () => {
  const form = useFormikContext<InfrastructureFormValue>();

  const InfrastructureForm = useFormikContext();
  const dispatch = useAppDispatch();

  const organizationOptions = useAppSelector(selectOrganizationOptions);
  const cloudOptions = useAppSelector(selectCloudOptions);
  const folderOptions = useAppSelector(selectFolderOptions);
  const networkOptions = useAppSelector(selectNetworkOptions);
  const cloudDelegationOptions = useAppSelector(selectCloudDelegationOptions);
  const cloudDelegations = useAppSelector(selectCloudDelegations);

  const cloudInfrastructure = useMemo(() => {
    const id = form.values.delegationId;
    return cloudDelegations?.find((infrastructure) => infrastructure.id === id);
  }, [form.values.delegationId]);

  InfrastructureForm.validationSchema = InfrastructureFormSchema;
  const [newFolder, setNewFolder] = useState<boolean>(false);
  const [newNetwork, setNewNetwork] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      loadCloudDelegations({
        delegationId: form.values.delegationId == null ? '' : form.values.delegationId,
      }),
    );
    dispatch(
      loadOrganizations({
        delegationId: form.values.delegationId == null ? '' : form.values.delegationId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      loadOrganizations({
        delegationId: form.values.delegationId == null ? '' : form.values.delegationId,
      }),
    );
    form.setFieldValue('organizationId', null);
  }, [form.values.delegationId]);

  useEffect(() => {
    dispatch(
      loadClouds({
        delegationId: form.values.delegationId == null ? '' : form.values.delegationId,
        parentId: form.values.organizationId,
      }),
    );
    let organizationName = '';
    if (form.values.organizationId) {
      let selected = organizationOptions.find((item) => item.value === form.values.organizationId);
      if (selected !== undefined) organizationName = selected.label;
    }
    form.setFieldValue('organizationName', organizationName);
  }, [form.values.organizationId]);

  useEffect(() => {
    dispatch(
      loadFolders({
        delegationId: form.values.delegationId == null ? '' : form.values.delegationId,
        parentId: form.values.cloudId,
      }),
    );
    let cloudName = '';
    if (form.values.cloudId) {
      let selected = cloudOptions.find((item) => item.value === form.values.cloudId);
      if (selected !== undefined) cloudName = selected.label;
    }
    form.setFieldValue('cloudName', cloudName);
  }, [form.values.cloudId]);

  useEffect(() => {
    if (cloudInfrastructure) {
      form.setFieldValue('organizationId', cloudInfrastructure.organization?.resourceId);
      form.setFieldValue('cloudId', cloudInfrastructure.cloud?.resourceId);
    }
    if (!cloudInfrastructure) {
      form.setFieldValue('organizationId', '');
      form.setFieldValue('cloudId', '');
    }
  }, [cloudInfrastructure]);

  useEffect(() => {
    dispatch(
      loadNetworks({
        delegationId: form.values.delegationId == null ? '' : form.values.delegationId,
        parentId: form.values.folderId,
      }),
    );
    if (form.values.folderId === '') {
      setNewFolder(true);
      setNewNetwork(true);
      form.setFieldValue('networkId', '');
    }
    let folderName = '';
    if (form.values.folderId && form.values.folderId !== '') {
      setNewFolder(false);
      setNewNetwork(false);

      let selected = folderOptions.find((item) => item.value === form.values.folderId);
      if (selected !== undefined) folderName = selected.label;
    }
    form.setFieldValue('folderName', folderName);
  }, [form.values.folderId]);

  useEffect(() => {
    if (form.values.networkId === '') setNewNetwork(true);

    let networkName = '';
    if (form.values.networkId && form.values.networkId !== '') {
      setNewNetwork(false);
      let selected = networkOptions.find((item) => item.value === form.values.networkId);
      if (selected !== undefined) networkName = selected.label;
    }
    form.setFieldValue('networkName', networkName);
  }, [form.values.networkId]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <Select
          name="delegationId"
          values={cloudDelegationOptions}
          label="Основание"
          isOutlined
          className={styles.form__field}
        />

        <InputField
          name="name"
          placeholder="Название инфраструктуры"
          type="text"
          className={styles.form__field}
        />

        <Select
          name="organizationId"
          values={organizationOptions}
          label="Организация"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.delegationId}
        />

        <Select
          name="cloudId"
          values={cloudOptions}
          label="Облако"
          isOutlined
          className={styles.form__field}
          disabled={!!form.values.delegationId}
        />
        <Select
          name="folderId"
          values={folderOptions}
          label="Каталог"
          isOutlined
          className={styles.form__field}
          value=""
        />
        {newFolder && (
          <InputField
            name="folderName"
            placeholder="Название каталога"
            type="text"
            className={styles.form__field}
          />
        )}
        <Select
          name="networkId"
          values={networkOptions}
          label="Сеть"
          isOutlined
          className={styles.form__field}
        />
        {newNetwork && (
          <InputField
            name="networkName"
            placeholder="Название сети"
            type="text"
            className={styles.form__field}
          />
        )}
      </div>
      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        Сохранить
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const InfrastructureForm = withFormik<InfrastructureFormProps, InfrastructureFormValue>({
  displayName: 'InfrastructureForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      organizationId: props.value.organizationId,
      organizationName: props.value.organizationName,
      cloudId: props.value.cloudId,
      cloudName: props.value.cloudName,
      folderId: props.value.folderId,
      folderName: props.value.folderName,
      networkId: props.value.networkId,
      networkName: props.value.networkName,
      serviceAccountId: props.value.serviceAccountId,
      serviceAccountName: props.value.serviceAccountName,
      delegationId: props.value.delegationId,
    };
  },
  enableReinitialize: true,
  validationSchema: InfrastructureFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
